<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="4.807 13.042 0.75 18 4.5 18.75 6 23.25 9.944 16.992"
    />
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="19.193 13.042 23.25 18 19.5 18.75 18 23.25 14.056 16.992"
    />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="12" cy="9" r="8.25" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M12.531,4.292,13.779,6.75h2.127a.582.582,0,0,1,.419,1L14.372,9.668l1.082,2.485a.589.589,0,0,1-.839.737L12,11.419,9.385,12.89a.589.589,0,0,1-.839-.737L9.628,9.668,7.675,7.746a.582.582,0,0,1,.419-1h2.127L11.47,4.292A.6.6,0,0,1,12.531,4.292Z"
    />
  </svg>
</template>
